
















import {Component, Prop, Watch, Vue} from 'vue-property-decorator';
import CountTo from 'vue-count-to';
import {collectionURLs} from '@/utils/config';

@Component({
  components: {
    CountTo,
  },
})
export default class Stats extends Vue {

  @Prop() data!: any[];

  goToCollection(collection: string) {
    this.$router.push(`/${collectionURLs[collection]}/overview`);
  }

}

