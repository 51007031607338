






import {Component, Vue} from 'vue-property-decorator';

import Stats from '@/views/dashboard/components/Stats.vue';
import {db} from '@/firebase';

@Component({
  components: {
    Stats,
  },
})
export default class Dashboard extends Vue {

  stats: any = [];

  icons: any = {
    LessonQA: 'book',
    LearningFeature: 'puzzle-piece',
    PracticeExam: 'pie-chart',
    TargetedReview: 'target',
    TrueFalse: 'radio-button',
  };

  created() {
    db.collection('Overview').get()
      .then((snapshot) => {
        snapshot.forEach((item: any) => {
          const overview = item.data();
          if (item.id !== 'LearningFeatureConfig') {
            this.stats.push({
              collection: item.id,
              icon: this.icons[item.id],
              count: overview.total,
            });
          }
        });
      });
  }
}
